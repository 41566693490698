import React from "react"
import {Link} from "gatsby"
import { Dropdown } from 'semantic-ui-react'

class Resource extends React.Component  {
  constructor(props) {
    super(props);
    this.filters = props.filters;
    this.data = props.data;
    this.page = props.page;
    this.count = props.count;


    this.handleClickAll = this.handleClickAll.bind(this);
    this.handleClickArticles = this.handleClickArticles.bind(this);
    this.handleClickApps = this.handleClickApps.bind(this);
    this.handleClickProducts = this.handleClickProducts.bind(this);
    this.handleClickVideos = this.handleClickVideos.bind(this);
    this.showMore = this.showMore.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);

    this.state = {
      isClickedAll: false,
      isClickedArticles: false,
      isClickedApps: false,
      isClickedProducts: false,
      isClickedVideos: false,
      showAll: false,
      width: 0
    };

    /* Init Isotope */
    if (typeof window !== `undefined`) {
      this.Isotope = require("isotope-layout/js/isotope"); // eslint-disable-line
    }

  }

  isotopeSettings(className) {
    if (this.iso === undefined)
    this.iso = new this.Isotope(".grid", {
      itemSelector: '.grid-item',
      layoutMode: "masonry",
      masonry: {
        gutter: 32,
        columnWidth: 30
      }
    });
    this.iso.arrange({ filter: className });
  }

  handleDropdownChange = (e, data) => {
    if( data.value === '*') {
      this.handleClickAll();
    }

    if( data.value === 'articles') {
      this.handleClickArticles();
    }

    if( data.value === 'apps') {
      this.handleClickApps();
    }

    if( data.value === 'products') {
      this.handleClickProducts();
    }

    if( data.value === 'videos') {
      this.handleClickVideos();
    }
  }

  showMore() {
    if( this.page === 'resources') {
      this.setState({
        showAll: true,
      });

      this.handleClickAll();
    }
  }

  filterShowAll() {
    if( this.page === 'resources') {
      this.setState({
        showAll: true,
      });
    }
  }

  // Set state when filters are clicked
  filterState(activeFilter) {
    let allState = activeFilter === "isClickedAll" ? true : false;
    let articlesState = activeFilter === "isClickedArticles" ? true : false;
    let appsState = activeFilter === "isClickedApps" ? true : false;
    let productsState = activeFilter === "isClickedProducts" ? true : false;
    let videosState = activeFilter === "isClickedVideos" ? true : false;
    this.setState({
      isClickedAll: allState,
      isClickedArticles: articlesState,
      isClickedApps: appsState,
      isClickedProducts: productsState,
      isClickedVideos: videosState,
    })
  }

  handleClickAll() {
    if (typeof window !== `undefined`) {
      this.filterState("isClickedAll");
      this.value = '*'
      setTimeout(() => this.isotopeSettings("*"), 100)
    }
  }

  handleClickArticles() {
    if (typeof window !== `undefined`) {
      this.filterShowAll();
      this.filterState("isClickedArticles");
      this.value = 'articles'
      setTimeout(() => this.isotopeSettings(".article"), 100)
    }
  }

  handleClickApps() {
    if (typeof window !== `undefined`) {
      this.filterShowAll();
      this.filterState("isClickedApps");
      this.value = 'apps'
      setTimeout(() => this.isotopeSettings(".app"), 100)
    }

  }

  handleClickProducts() {
    if (typeof window !== `undefined`) {
      this.filterShowAll();
      this.filterState("isClickedProducts");
      this.value = 'products'
      setTimeout(() => this.isotopeSettings(".product"), 100)

    }
  }

  handleClickVideos() {
    if (typeof window !== `undefined`) {
      this.filterShowAll();
      this.filterState("isClickedVideos");
      this.value = 'videos'
      setTimeout(() => this.isotopeSettings(".video"), 100)
    }
  }

  componentDidMount() {

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    // when the page opens at first
    if(typeof window !== `undefined`){
      window.addEventListener("load", this.handleClickAll);
      }
    // users navigate to this page
    this.handleClickAll();

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState(() => ({
      width: window.innerWidth
    }));
  }

  render() {

    let itemCount = this.count,
          showAll = this.state.showAll;

    if( this.state.width <= 992 && this.page === 'home' ) {
      itemCount = 3;
    }

    const card = this.data.map(function(item) {
      const showItem = item.id > itemCount && showAll === false ? ' hide' : '';
      return (
        <div key={item.id} className={"grid-item " + item.type.toLowerCase() + showItem }>
          <a href={ item.url } target="_blank" rel="noopener noreferrer" className="resource__card">
          { item.image.src && item.type !== 'App' ?
          <div className="resource__image-wrapper">
            <img src={ item.image.src } className="resource__image img-fluid" alt={ item.title } />
          </div>
          :""
          }
          <div className="resource__card-body">
            { item.image.src && item.type === 'App' ?
              <img src={ item.image.src } className="resource__image img-fluid" alt={ item.title } />
            :""
            }
            <div className="resource__type">{ item.type }</div>
            <div className="resource__title">{ item.title }</div>
            { item.description ?
            <div className="resource__description">{ item.description }</div>
            :""
            }
          </div>
        </a>
      </div>
      )
    });

    const resourceHeader = (page) => {
      return (
        <div className="resource-header">
          <div className="container">
          { page === 'home' ?
            <div className="resource-header__wrapper">
              <div className="resource-header__ellipse"></div>
              <div className="resource-header__title">Resources</div>
              <div className="resource-header__description">Everything you need to find <span className="green-text">balance</span> with technology</div>
            </div>
          : ""
          }
          { page === 'resources' ?
            <div className="resource-header__wrapper">
              <div className="resource-header__page-title">Resources</div>
            </div>
          : ""
          }
          </div>
        </div>
      )
    }
    const ctaButton = (page, hasButton) => {
      return (
        <div className="resource__button-wrapper">
          { page === 'home' ?
          <Link to="/resources" aria-label="Recourse Archive" className="button button--green-ghost">See all resources</Link>
          : ""
          }
          { page === 'resources' && this.data.length > this.count && hasButton === false ?
            <button className="button button--green-ghost" onClick={this.showMore}>+ Show more</button>
          : ""
          }
        </div>
      )
    }

    return (
      <div
        className={
          this.page === 'resources'
          ? "resource-wrapper"
          : "resource-wrapper resource-wrapper--has-wave"
        }
      >
        { this.state.showAll }
        { resourceHeader(this.page) }
        <div className="container">
          {this.page === 'resources' ?
            <div className="dropdown-wrapper d-lg-none">
              <Dropdown
              placeholder='All'
              fluid
              selection
              value={this.value}
              options={this.filters}
              onChange={this.handleDropdownChange}
              />
            </div>
            : ""
          }
          <div className="filters-button-group d-none d-lg-block">
            <button
              onClick={this.handleClickAll}
              className={
                this.state.isClickedAll
                  ? "filters-button button-all is-checked"
                  : "filters-button button-all"
              }
            >
              All
            </button>
            <button
              onClick={this.handleClickArticles}
              className={
                this.state.isClickedArticles
                  ? "filters-button button-articles is-checked"
                  : "filters-button button-articles"
              }
            >
              Articles
            </button>
            <button
              onClick={this.handleClickApps}
              className={
                this.state.isClickedApps
                  ? "filters-button button-apps is-checked"
                  : "filters-button button-apps"
              }
            >
              Apps
            </button>
            <button
              onClick={this.handleClickProducts}
              className={
                this.state.isClickedProducts
                  ? "filters-button button-products is-checked"
                  : "filters-button button-products"
              }
            >
              Products
            </button>
            <button
              onClick={this.handleClickVideos}
              className={
                this.state.isClickedVideos
                  ? "filters-button button-videos is-checked"
                  : "filters-button button-videos"
              }
            >
              Videos
            </button>
          </div>
          <div className="resource grid">
            { card }
          </div>
          { ctaButton(this.page, this.state.showAll) }
        </div>
      </div>
    );
  }
}

export default Resource
