import { useStaticQuery, graphql } from 'gatsby';

const useResources = () => {
  const data = useStaticQuery(graphql`
    {
      allResourcesJson {
        nodes {
          id
          url
          title
          type
          description
          image {
            src
          }
        }
      }
    }
  `);

  return data.allResourcesJson.nodes;
};

export default useResources;
