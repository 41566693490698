import { useStaticQuery, graphql } from 'gatsby';

const useResourcesFilters = () => {
  const data = useStaticQuery(graphql`
    {
      allResourcesFiltersJson {
        nodes {
          key
          text
          value
        }
      }
    }
  `);

  return data.allResourcesFiltersJson.nodes;
};

export default useResourcesFilters;
